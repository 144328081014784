<template>
  <div>

    <CustomerForm
      :formStatus="formStatus"
      @dismiss="dismissForm"
      :customer="customer"
      :mode="mode"
    ></CustomerForm>

    <ConfirmDelete
      :dialog="confirmDeleteCustomerDialog"
      :item="customer"
      @delete="deleteCustomer($event)"
      @dismiss="dismissFn($event)"
    />

    <v-row>
      <v-col cols="12">
        <h3>Customer Details</h3>
      </v-col>

      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-card-title class="d-flex align-center justify-space-between">
            <div>
              <v-text-field
                outlined
                depressed
                dense
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>

            <div>
              <v-btn color="primary" elevation="0" @click.stop="createCustomer">
                <v-icon left dark> mdi-plus </v-icon> New Customer</v-btn
              >
            </div>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="customers"
            item-key="name"
            :search="search"
            :loading="loading"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.id="{ item }">
              <h4 class="font-weight-medium">{{ item?.id }}</h4>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.customer_name="{ item }">
              {{ item?.name }}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.email="{ item }">
              {{ item?.email }}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.client="{ item }">
              {{ item?.amount_due ?? "-" }}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.client_logo="{ item }">
              <img :src="item?.logo" height="15" />
            </template>

            <!-- action -->
            <template #[`item.action`]="{ item }">
              <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editCustomer(item)">
                    <v-list-item-title
                      ><v-icon fab small class="me-2">mdi-pencil</v-icon>
                      Edit Customer
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="setDeleteCustomer(item)">
                    <v-list-item-title
                      ><v-icon small color="error" class="me-2"
                        >mdi-delete</v-icon
                      >
                      Delete Customer</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template> </v-data-table
          ><v-divider></v-divider> </v-card
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import CustomerForm from "../../../components/Billing/CustomerForm.vue"
import ConfirmDelete from "../../../components/ConfirmDelete.vue"
export default {
  components: {
    CustomerForm,
    ConfirmDelete
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Customer Name",
          value: "customer_name",
        },
        {
          text: "Logo",
          value: "client_logo",
        },
        {
          text: "Contact",
          value: "email",
        },
        {
          text: "Billing Address",
          value: "billing_address",
          width: "320px",
        },
        {
          text: "Amount Due",
          value: "amount_due",
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false,
        },
      ],
      size: ["10", "20", "50"],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        itemsPerPage: 10,
      },
      formStatus: false,
      mode: "new",
      customer: null,
      loading: false,
      confirmDeleteCustomerDialog: false,
    }
  },
  mounted() {
    this.getCustomers()
  },
  methods: {
    async getCustomers() {
      try {
        this.loading = true
        await this.$store.dispatch("billing/fetchCustomers")
        this.loading = false
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async createCustomer() {
      this.mode = "new"
      ;(this.customer = null), (this.formStatus = !this.formStatus)
    },
    async editCustomer(customer) {
      this.mode = "edit"
      this.customer = customer
      this.formStatus = !this.formStatus
    },
    async deleteCustomer(id) {

      this.loading = true
      
      try {
        this.confirmDeleteCustomerDialog = false

        await this.$store.dispatch("billing/deleteCustomer", id)

        this.getCustomers()
        
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    dismissForm() {
      this.formStatus = false
    },
    dismissFn() {
      this.confirmDeleteCustomerDialog = false
    },
    setDeleteCustomer(customer) {
      this.confirmDeleteCustomerDialog = true
      this.customer = customer
    },
    setPageNumber(page) {
      this.pagination.page = page
    },
  },
  computed: {
    customers() {
      return this.$store.state.billing.customers
    },
  },
}
</script>
