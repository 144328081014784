<template>
  <div>
    <v-navigation-drawer
      :value="formStatus"
      @input="handleDrawerModelValueUpdate"
      fixed
      temporary
      right
      width="40%"
    >
      <v-card flat>
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h5>
            {{ mode == "new" ? "New Customer" : "Update Customer" }}
          </h5>
          <v-btn icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-divider></v-divider>

        <v-form ref="form" :value="validity" lazy-validation>
          <v-card-text>
            <v-row class="flex-column">

              <v-col>
                <h5 class="mb-1">Customer Name *</h5>
                <v-text-field
                  v-model="name"
                  single-line
                  outlined
                  dense
                  label="Customer Name"
                  hide-details="auto"
                  required
                  :rules="nameValidity"
                ></v-text-field>
              </v-col>

              <v-col>
                <h5 class="mb-1">Email Address *</h5>
                <v-text-field
                  v-model="email"
                  single-line
                  outlined
                  dense
                  label="Customer email"
                  hide-details="auto"
                  required
                  :rules="emailValidity"
                ></v-text-field>
              </v-col>

              <v-col>
                <h5 class="mb-1">Billing Address</h5>
                <v-textarea
                  v-model="billing_address"
                  outlined
                  dense
                  label="Billing Address"
                  hide-details="auto"
                ></v-textarea>
                </v-col>

              <v-col>
                <h5 class="mb-1">Logo</h5>
                <v-text-field
                  v-model="logo"
                  single-line
                  outlined
                  dense
                  label="Logo"
                  hide-details="auto"
                  hint="Add a link to the customer's logo"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>

      <template #append>
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="loading"
              :loading="loading"
              type="submit"
              @click="submitForm"
              block
              depressed
              color="primary"
              class="mt-2"
              >Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    formStatus: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  data() {
    return {
      loading: false,
      name: null,
      email: null,
      billing_address: null,
      logo: '',
      nameValidity: [(value) => !!value || " Customer name is required"],
      emailValidity: [
        (value) => !!value || "Email is required",
        (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
      ],
      validity: true,
    }
  },
  watch: {
    customer() {
      if (this.customer) {
        this.name = this.customer?.name
        this.email = this.customer?.email
        this.billing_address = this.customer?.billing_address
        this.logo = this.customer?.logo
      }
    },
  },
  methods: {
    async getCustomers() {
      try {
        this.loading = true
        await this.$store.dispatch("billing/fetchCustomers")
        this.loading = false
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    dismiss() {
      this.$emit("dismiss")
    },
    handleDrawerModelValueUpdate(value) {
      if (value == false) {
        this.$emit("dismiss")
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
    },
    async submitForm() {
      const isvalid = this.$refs.form.validate()
      if (isvalid) {
        this.loading = true

        try {
          let body = {}

          if (this.mode == "new") {
            body = {
              name: this.name,
              email: this.email,
              billing_address: this.billing_address,
              logo: this.logo,
            }
          } else {
            body = {
              id: this.customer?.id,
              body: {
                name: this.name,
                email: this.email,
                billing_address: this.billing_address,
                logo: this.logo,
              },
            }
          }

          console.log(body)

          if (this.mode == "new") {
            await this.$store.dispatch('billing/createCustomer', body)
          } else {
            await this.$store.dispatch('billing/updateCustomer', body)
          }

          this.dismiss()

          this.getCustomers()

          this.$notify({
            title:
              this.mode == "new"
                ? "Customer created successfully!"
                : "Customer updated successfully!",
            group: "success",
          })
        } catch (error) {
          console.log(error)
          this.$notify({
            title: "An Error Occured, Please Try Again!",
            group: "errors",
          })
        } finally {
          this.loading = false
        }
      }
    },
  },
  computed: {
    __isDrawerOpen() {
      return this.formStatus
    }
  },
}
</script>
